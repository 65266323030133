import { Axios, AxiosResponse } from 'axios';
import { instance } from 'helper/api/api';
import { AlgoliaSecuredApiKeyResponse } from 'models/Algolia';

const ALGOLIA_SECURED_API_KEY = 'algolia-secured-api-key-web';

export class AlgoliaService {
  algoliaInstance: Axios;

  constructor(algoliaInstance: Axios) {
    this.algoliaInstance = algoliaInstance;
  }

  getAlgoliaApiKeyLocalStorage = (): string | null =>
    localStorage.getItem(ALGOLIA_SECURED_API_KEY);

  setAlgoliaApiKeyLocalStorage = (
    apiKeyResponse: AlgoliaSecuredApiKeyResponse
  ) =>
    localStorage.setItem(
      ALGOLIA_SECURED_API_KEY,
      JSON.stringify(apiKeyResponse)
    );

  fetchAlgoliaApiKey = async (): Promise<
    AxiosResponse<AlgoliaSecuredApiKeyResponse>
  > => await this.algoliaInstance.post(`search/apiKey`);

  getAlgoliaApiKey = async (): Promise<string> => {
    const securedApiKey = this.getAlgoliaApiKeyLocalStorage();

    if (
      !securedApiKey ||
      new Date().getTime() > JSON.parse(securedApiKey).validUntil
    ) {
      const { data } = await this.fetchAlgoliaApiKey();
      this.setAlgoliaApiKeyLocalStorage(data);
      return data.apiKey;
    }

    return JSON.parse(securedApiKey).apiKey;
  };

  deleteAlgoliaKey = () => {
    localStorage.removeItem(ALGOLIA_SECURED_API_KEY);
  };
}

export default new AlgoliaService(instance);
