import React from 'react';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import ButtonAntd from 'antd/lib/button';
import classnames from 'classnames';
import Typography from '../typography/Typography';
import { TrackEventHandler } from '../types';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'tip'
  | 'info'
  | 'tab'
  | 'filter'
  | 'accent'
  | 'icon';

export type ButtonMode = 'light';

export interface ButtonProps
  extends Omit<AntdButtonProps, 'type' | 'size' | 'shape'> {
  type?: ButtonType;
  variant?: 'filled' | 'outline' | 'link' | 'text' | 'carousel';
  size?: 's' | 'm' | 'l';
  fullWidth?: boolean;
  grow?: boolean;
  shrink?: boolean;
  shape?: AntdButtonProps['shape'] | 'square';
  minWidth?: React.CSSProperties['minWidth'];
  textLink?: boolean;
  iconAlign?: 'l' | 'r';
  testId?: string;
  eventName?: string;
  eventElementName?: string;
  eventElementUrl?: string;
  hasTooltip?: boolean;
  mode?: ButtonMode;
  trackEvent?: TrackEventHandler;
}

const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      size = 'm',
      type = 'primary',
      variant = 'filled',
      icon,
      fullWidth = false,
      grow = false,
      shrink = false,
      shape = 'round',
      minWidth = '0px',
      textLink = false,
      iconAlign = 'l',
      testId,
      eventName = 'USER_CLICK',
      mode,
      eventElementName,
      eventElementUrl,
      trackEvent,
      ...props
    }: ButtonProps,
    ref
  ): JSX.Element => {
    const mixpanelElementName =
      eventElementName ||
      testId ||
      // @ts-ignore
      props['data-testid'];
    const button = (
      <ButtonAntd
        data-testid={testId}
        {...props}
        ref={ref}
        shape={shape === 'square' || type === 'icon' ? undefined : shape}
        className={classnames(
          props.className,
          `custom-btn`,
          `custom-btn-${type}`,
          Boolean(mode)
            ? `custom-btn-${type}-${variant}-${mode}`
            : `custom-btn-${type}-${variant}`,
          `custom-btn-${shape}`,
          type === 'icon' ? '' : `custom-btn-${size}`,
          {
            'w-100': fullWidth,
            'custom-btn-grow': grow,
            'custom-btn-shrink': shrink,
            'custom-btn-text-link': textLink,
            [`custom-btn-icon-position-${iconAlign}`]:
              Boolean(props.children) && Boolean(icon),
          }
        )}
        style={{ ...props.style, minWidth }}
        icon={icon ? <span className="custom-btn__icon">{icon}</span> : null}
        onClick={(e) => {
          if (mixpanelElementName && trackEvent) {
            trackEvent({
              event: eventName,
              element: mixpanelElementName,
              url: eventElementUrl,
            });
          }
          props.onClick?.(e);
        }}
      >
        {props.children ? (
          <Typography
            customClass="custom-btn__typography"
            type="button"
            size={size}
            ellipsis
          >
            <span>{props.children}</span>
          </Typography>
        ) : null}
      </ButtonAntd>
    );

    return props?.hasTooltip && props?.disabled ? (
      <div
        className="button-disabled-wrapper"
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onMouseDown={props.onMouseDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        tabIndex={0}
      >
        {button}
      </div>
    ) : (
      button
    );
  }
);

export default Button;
