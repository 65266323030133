import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import Container from 'components/container/Container';
import Typography from 'component-library/src/typography/Typography';

import ScreenCondition from 'components/screen-condition/ScreenCondition';
import { Space } from 'antd';
import { spacing } from 'component-library/src/theme/theme';

export interface SubheaderProps {
  statusTag?: ReactNode;
  title?: ReactNode;
  titleClass?: string;
  link?: string;
  actionItems?: ReactNode;
  actionItemsFull?: boolean;
  testId?: string;
}

const Subheader: React.FC<React.PropsWithChildren<SubheaderProps>> = ({
  title,
  titleClass,
  link,
  statusTag,
  actionItems,
  actionItemsFull,
  testId,
  children,
}) => (
  <div className="subheader" data-testid={testId}>
    <Container>
      <Space direction="vertical" className="w-100" size={spacing.space8}>
        <div
          className={classnames('subheader-container', {
            'subheader-container-full': actionItemsFull,
          })}
        >
          <div className="title-link-container">
            {link && (
              <Link
                to={link}
                className="link"
                data-testid="release-page-back-link"
              >
                <ArrowLeftOutlined className="icon" />
              </Link>
            )}

            <Typography
              ellipsis
              customClass={classnames('subheader-title', titleClass)}
              data-testid="subheader-title-text"
              type="headline"
              color="secondary7"
              size="xl"
            >
              {title ? title : '-'}
            </Typography>

            {Boolean(statusTag) && (
              <ScreenCondition hideOn="md">
                <div
                  className="subheader-status"
                  data-testid={`${testId}-status`}
                >
                  {statusTag}
                </div>
              </ScreenCondition>
            )}
          </div>

          <div className="status-actions-container">
            {actionItems && <div>{actionItems}</div>}
          </div>
        </div>

        {children}
      </Space>
    </Container>
  </div>
);

export default Subheader;
