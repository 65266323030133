import { useFeatureFlags } from './useFeatureFlags';
import { useAuth } from './useAuth';
import flags from 'config/flags';

export interface UseFeatureFlagCheck {
  loading: boolean;
  value: boolean;
}

export const useFeatureFlagCheck = (
  flagName?: keyof typeof flags
): UseFeatureFlagCheck => {
  const flagContext = useFeatureFlags();
  const authContext = useAuth();
  if (!flagName) {
    return { loading: false, value: true };
  }
  if (flagContext.loading) {
    return { loading: flagContext.loading, value: false };
  }
  const flag = flagContext.flags.find((flag) => flag.name === flagName);

  if (!flag) {
    return { loading: false, value: false };
  }

  const value = authContext.user.isInternalUser
    ? flag.isOnForInternalUsers
    : flag.isOn;

  return {
    loading: false,
    value: typeof value === 'undefined' ? false : value,
  };
};
