import api from 'helper/api/api';
import React, { createContext } from 'react';
import { ApiSpec } from 'specs/api-spec';
import useSWR from 'swr';

export type FeatureFlag = ApiSpec['schemas']['FeatureFlag'];
export interface FeatureFlagContextProps {
  flags: FeatureFlag[];
  loading: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextProps>({
  flags: [],
  loading: false,
});

export const FeatureFlagContextProvider: React.FC<React.PropsWithChildren<
  unknown
>> = ({ children }) => {
  const featureFlagRequest = useSWR<FeatureFlag[]>(
    '/featureFlags',
    () => api.featureFlags.get().then((flagResponse) => flagResponse.data),
    {
      revalidateOnFocus: true,
    }
  );

  const props: FeatureFlagContextProps = {
    flags: featureFlagRequest?.data || [],
    loading: !featureFlagRequest?.data && featureFlagRequest.isValidating,
  };

  return (
    <FeatureFlagContext.Provider value={props}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
