import React from 'react';
import Title, { TitleProps } from 'antd/lib/typography/Title';
import Text, { TextProps } from 'antd/lib/typography/Text';
import classnames from 'classnames';
import { ThemeColorProps, getThemeColor } from '../theme/theme';

export interface CustomTypographyProps
  extends ThemeColorProps,
    Omit<TitleProps, 'ellipsis' | 'type' | 'level' | 'className' | 'color'>,
    Omit<TextProps, 'ellipsis' | 'type' | 'className' | 'color'> {
  ellipsis?: boolean;
  ellipsisClamp?: 2 | 3; // default is 1 with ellipsis set true
  block?: boolean;
  customClass?: string;
  align?: 'center' | 'right' | 'left';
  weightLevel?: '400' | '500' | '600' | '700' | '800';
  responsiveFont?: boolean;
  /**
    Use if text wraps to display inline so that line-height is respected
  */
  multiline?: boolean;
}

export type CustomTypographyStyleProps =
  | {
      type: 'label';
      size: 'default';
    }
  | {
      type: 'body' | 'button';
      size: 'xs' | 's' | 'm' | 'l';
    }
  | {
      type: 'headline';
      size: 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';
      level?: TitleProps['level'];
    };

const Typography = ({
  type,
  size,
  color = 'neutral',
  style = {},
  block = false,
  align,
  customClass,
  weightLevel,
  responsiveFont = true,
  multiline = false,
  ellipsisClamp,
  ...props
}: CustomTypographyProps & CustomTypographyStyleProps): JSX.Element => {
  if (type === 'headline') {
    return (
      <Title
        className={classnames('custom-typography', `text-${type}-${size}`, {
          [`text-responsive`]: responsiveFont,
          [`text-align-${align}`]: Boolean(align),
          'text-block': block,
          'text-inline': multiline,
          [`bold-${weightLevel}`]: Boolean(weightLevel),
          [`text-ellipsis-clamp-${ellipsisClamp}`]: Boolean(ellipsisClamp),
          ...(customClass ? { [customClass]: true } : {}),
        })}
        style={{ color: getThemeColor(color), ...style }}
        {...props}
      >
        {props.children}
      </Title>
    );
  } else {
    return (
      <Text
        className={classnames('custom-typography', `text-${type}-${size}`, {
          [`text-responsive`]: responsiveFont,
          [`text-align-${align}`]: Boolean(align),
          'text-block': block,
          'text-inline': multiline,
          [`bold-${weightLevel}`]: Boolean(weightLevel),
          [`text-ellipsis-clamp-${ellipsisClamp}`]: Boolean(ellipsisClamp),
          ...(customClass ? { [customClass]: true } : {}),
        })}
        style={{ color: getThemeColor(color), ...style }}
        {...props}
      >
        {props.children}
      </Text>
    );
  }
};

export default Typography;
