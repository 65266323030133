import { MusicAnalyticsToken } from 'models/MusicAnalytics';
import api from '../api/api';

const MUSIC_ANALYTICS_STORAGE_KEY = 'MUSIC_ANALYTICS_STORAGE_KEY';

export class MusicAnalyticsAuthentication {
  token?: MusicAnalyticsToken;
  getTokenEventName = 'get-token-success';
  getTokenPending = false;
  getTokenEvent = new Event(this.getTokenEventName);

  constructor() {
    this.init();
  }

  private async init(): Promise<void> {
    const storedToken = localStorage.getItem(MUSIC_ANALYTICS_STORAGE_KEY);
    if (storedToken) {
      this.token = JSON.parse(storedToken) as MusicAnalyticsToken;
    }
  }

  /**
   * Stores the token in local storage
   * @param token
   * @private
   */
  private setToken(token: MusicAnalyticsToken): void {
    const tokenWithExpiresTs: MusicAnalyticsToken = {
      ...token,
      expires: new Date().getTime() + token.expires_in,
    };
    localStorage.setItem(
      MUSIC_ANALYTICS_STORAGE_KEY,
      JSON.stringify(tokenWithExpiresTs)
    );
    this.token = tokenWithExpiresTs;
  }

  /**
   * Gets a new token for EMS Music Analytics via our API.
   * Only calls the api if no token exist in local storage or the token is expired
   */
  private async _getMusicAnalyticsToken(): Promise<void> {
    this.getTokenPending = true;
    try {
      if (!this.token || new Date().getTime() > this.token.expires) {
        const {
          data: musicAnalyticsToken,
        } = await api.musicAnalytics.getToken();
        this.setToken(musicAnalyticsToken);
      }
      this.getTokenPending = false;
      document.dispatchEvent(this.getTokenEvent);
    } catch (e) {
      this.getTokenPending = false;
      throw e;
    }
  }

  /**
   * Uses event stream proxy to _getMusicAnalyticsToken to ensure that concurrent requests are handled and repeat requests to create auth tokens are avoided
   */
  public async getMusicAnalyticsToken(): Promise<string | undefined> {
    return new Promise(async (resolve, reject) => {
      if (!this.getTokenPending) {
        await this._getMusicAnalyticsToken().catch(reject);
        resolve(this.token?.access_token);
        return;
      }

      document.addEventListener(
        this.getTokenEventName,
        function handler(this: MusicAnalyticsAuthentication) {
          document.removeEventListener(this.getTokenEventName, handler, false);
          resolve(this.token?.access_token);
        }.bind(this),
        false
      );
    });
  }

  /**
   * Handles Sign Out logic: removes localStorage Key
   */
  public signOut(): void {
    localStorage.removeItem(MUSIC_ANALYTICS_STORAGE_KEY);
  }
}

export default new MusicAnalyticsAuthentication();
